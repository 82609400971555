import loadable from '@loadable/component';

export const CFAirplanePaper = loadable(() => import('./index'), {
  resolveComponent: components => components.CFAirplanePaper,
});

export const CFAnalyticsBots = loadable(() => import('./index'), {
  resolveComponent: components => components.CFAnalyticsBots,
});

export const CFAnalyticsData = loadable(() => import('./index'), {
  resolveComponent: components => components.CFAnalyticsData,
});

export const CFAnalyticsNetwork = loadable(() => import('./index'), {
  resolveComponent: components => components.CFAnalyticsNetwork,
});

export const CFAnalyticsPie = loadable(() => import('./index'), {
  resolveComponent: components => components.CFAnalyticsPie,
});

export const CFArrow = loadable(() => import('./index'), {
  resolveComponent: components => components.CFArrow,
});

export const CFArrowBackward = loadable(() => import('./CFArrowBackward'), {
  resolveComponent: components => components.CFArrowBackward,
});

export const CFArrowExternal = loadable(() => import('./index'), {
  resolveComponent: components => components.CFArrowExternal,
});

export const CFArrowExternalBordered = loadable(() => import('./index'), {
  resolveComponent: components => components.CFArrowExternalBordered,
});

export const CFArrowTwoWay = loadable(() => import('./index'), {
  resolveComponent: components => components.CFArrowTwoWay,
});

export const CFAttacker = loadable(() => import('./index'), {
  resolveComponent: components => components.CFAttacker,
});

export const CFBenefitsHealthCare = loadable(() => import('./index'), {
  resolveComponent: components => components.CFBenefitsHealthCare,
});

export const CFBenefitsPaidVacation = loadable(() => import('./index'), {
  resolveComponent: components => components.CFBenefitsPaidVacation,
});

export const CFBenefitsParentalLeave = loadable(() => import('./index'), {
  resolveComponent: components => components.CFBenefitsParentalLeave,
});

export const CFBenefitsReturnship = loadable(() => import('./index'), {
  resolveComponent: components => components.CFBenefitsReturnship,
});

export const CFBenefitsSalary = loadable(() => import('./index'), {
  resolveComponent: components => components.CFBenefitsSalary,
});

export const CFBrowserVideo = loadable(() => import('./index'), {
  resolveComponent: components => components.CFBrowserVideo,
});

export const CFCalendar = loadable(() => import('./index'), {
  resolveComponent: components => components.CFCalendar,
});

export const CFCaretDown = loadable(() => import('./index'), {
  resolveComponent: components => components.CFCaretDown,
});

export const CFCaretLeft = loadable(() => import('./index'), {
  resolveComponent: components => components.CFCaretLeft,
});

export const CFCaretReorder = loadable(() => import('./index'), {
  resolveComponent: components => components.CFCaretReorder,
});

export const CFCaretRight = loadable(() => import('./index'), {
  resolveComponent: components => components.CFCaretRight,
});

export const CFCaretUp = loadable(() => import('./index'), {
  resolveComponent: components => components.CFCaretUp,
});

export const CFCaseStudy = loadable(() => import('./index'), {
  resolveComponent: components => components.CFCaseStudy,
});

export const CFCellTower = loadable(() => import('./index'), {
  resolveComponent: components => components.CFCellTower,
});

export const CFCertificate = loadable(() => import('./index'), {
  resolveComponent: components => components.CFCertificate,
});

export const CFCertificateManager = loadable(() => import('./index'), {
  resolveComponent: components => components.CFCertificateManager,
});

export const CFCheck = loadable(() => import('./CFCheck'), {
  resolveComponent: components => components.CFCheck,
});

export const CFChevronLeft = loadable(() => import('./index'), {
  resolveComponent: components => components.CFChevronLeft,
});

export const CFCloudflareAccess = loadable(() => import('./index'), {
  resolveComponent: components => components.CFCloudflareAccess,
});

export const CFCloudflareApi = loadable(() => import('./index'), {
  resolveComponent: components => components.CFCloudflareApi,
});

export const CFCloudflareBrowser = loadable(() => import('./index'), {
  resolveComponent: components => components.CFCloudflareBrowser,
});

export const CFCloudflareGateway = loadable(() => import('./index'), {
  resolveComponent: components => components.CFCloudflareGateway,
});

export const CFCloudflarePages = loadable(() => import('./index'), {
  resolveComponent: components => components.CFCloudflarePages,
});

export const CFCloudflareRadar = loadable(() => import('./index'), {
  resolveComponent: components => components.CFCloudflareRadar,
});

export const CFCloudflareRegistrar = loadable(() => import('./index'), {
  resolveComponent: components => components.CFCloudflareRegistrar,
});

export const CFCloudflareSpectrum = loadable(() => import('./index'), {
  resolveComponent: components => components.CFCloudflareSpectrum,
});

export const CFCloudflareStreamDelivery = loadable(() => import('./index'), {
  resolveComponent: components => components.CFCloudflareStreamDelivery,
});

export const CFCloudflareWarp = loadable(() => import('./index'), {
  resolveComponent: components => components.CFCloudflareWarp,
});

export const CFCloudflareWorkers = loadable(() => import('./index'), {
  resolveComponent: components => components.CFCloudflareWorkers,
});

export const CFCloudHybrid = loadable(() => import('./index'), {
  resolveComponent: components => components.CFCloudHybrid,
});

export const CFCloudInternet = loadable(() => import('./index'), {
  resolveComponent: components => components.CFCloudInternet,
});

export const CFCloudMulti = loadable(() => import('./index'), {
  resolveComponent: components => components.CFCloudMulti,
});

export const CFCloudUpload = loadable(() => import('./index'), {
  resolveComponent: components => components.CFCloudUpload,
});

export const CFCodeApi = loadable(() => import('./index'), {
  resolveComponent: components => components.CFCodeApi,
});

export const CFCodeContentOptimization = loadable(() => import('./index'), {
  resolveComponent: components => components.CFCodeContentOptimization,
});

export const CFCodeJs = loadable(() => import('./index'), {
  resolveComponent: components => components.CFCodeJs,
});

export const CFCollapse = loadable(() => import('./index'), {
  resolveComponent: components => components.CFCollapse,
});

export const CFConnect = loadable(() => import('./index'), {
  resolveComponent: components => components.CFConnect,
});

export const CFConnect2 = loadable(() => import('./index'), {
  resolveComponent: components => components.CFConnect2,
});

export const CFConnect3 = loadable(() => import('./index'), {
  resolveComponent: components => components.CFConnect3,
});

export const CFConnectIot = loadable(() => import('./index'), {
  resolveComponent: components => components.CFConnectIot,
});

export const CFCopyDuplicate = loadable(() => import('./index'), {
  resolveComponent: components => components.CFCopyDuplicate,
});

export const CFCreditCard = loadable(() => import('./index'), {
  resolveComponent: components => components.CFCreditCard,
});

export const CFDDOSAttack = loadable(() => import('./index'), {
  resolveComponent: components => components.CFDDOSAttack,
});

export const CFDeviceDesktop = loadable(() => import('./index'), {
  resolveComponent: components => components.CFDeviceDesktop,
});

export const CFDeviceLaptop = loadable(() => import('./index'), {
  resolveComponent: components => components.CFDeviceLaptop,
});

export const CFDeviceMobile = loadable(() => import('./index'), {
  resolveComponent: components => components.CFDeviceMobile,
});

export const CFDeviceTablet = loadable(() => import('./index'), {
  resolveComponent: components => components.CFDeviceTablet,
});

export const CFDocumentationLogs = loadable(() => import('./index'), {
  resolveComponent: components => components.CFDocumentationLogs,
});

export const CFDocumentationRules = loadable(() => import('./index'), {
  resolveComponent: components => components.CFDocumentationRules,
});

export const CFDocumentClipboard = loadable(() => import('./index'), {
  resolveComponent: components => components.CFDocumentClipboard,
});

export const CFDocumentList = loadable(() => import('./index'), {
  resolveComponent: components => components.CFDocumentList,
});

export const CFDownload = loadable(() => import('./index'), {
  resolveComponent: components => components.CFDownload,
});

export const CFDurableObjects = loadable(() => import('./index'), {
  resolveComponent: components => components.CFDurableObjects,
});

export const CFEaseOfUse = loadable(() => import('./index'), {
  resolveComponent: components => components.CFEaseOfUse,
});

export const CFEdgeLogDelivery = loadable(() => import('./index'), {
  resolveComponent: components => components.CFEdgeLogDelivery,
});

export const CFEdit = loadable(() => import('./index'), {
  resolveComponent: components => components.CFEdit,
});

export const CFEmail = loadable(() => import('./index'), {
  resolveComponent: components => components.CFEmail,
});

export const CFExpand = loadable(() => import('./index'), {
  resolveComponent: components => components.CFExpand,
});

export const CFEyeball = loadable(() => import('./index'), {
  resolveComponent: components => components.CFEyeball,
});

export const CFFaceHappy = loadable(() => import('./index'), {
  resolveComponent: components => components.CFFaceHappy,
});

export const CFFaceSad = loadable(() => import('./index'), {
  resolveComponent: components => components.CFFaceSad,
});

export const CFFiltering = loadable(() => import('./index'), {
  resolveComponent: components => components.CFFiltering,
});

export const CFGeoKeyManager = loadable(() => import('./index'), {
  resolveComponent: components => components.CFGeoKeyManager,
});

export const CFHamburger = loadable(() => import('./index'), {
  resolveComponent: components => components.CFHamburger,
});

export const CFHamburgerWide = loadable(() => import('./index'), {
  resolveComponent: components => components.CFHamburgerWide,
});

export const CFHealthCheck = loadable(() => import('./index'), {
  resolveComponent: components => components.CFHealthCheck,
});

export const CFHelpGiving = loadable(() => import('./index'), {
  resolveComponent: components => components.CFHelpGiving,
});

export const CFHelpQuestion = loadable(() => import('./index'), {
  resolveComponent: components => components.CFHelpQuestion,
});

export const CFImage = loadable(() => import('./index'), {
  resolveComponent: components => components.CFImage,
});

export const CFIndustry = loadable(() => import('./index'), {
  resolveComponent: components => components.CFIndustry,
});

export const CFIndustryGaming = loadable(() => import('./index'), {
  resolveComponent: components => components.CFIndustryGaming,
});

export const CFInnovationIntelligence = loadable(() => import('./index'), {
  resolveComponent: components => components.CFInnovationIntelligence,
});

export const CFInnovationThinking = loadable(() => import('./index'), {
  resolveComponent: components => components.CFInnovationThinking,
});

export const CFInternetBrowser = loadable(() => import('./index'), {
  resolveComponent: components => components.CFInternetBrowser,
});

export const CFIpTruncation = loadable(() => import('./index'), {
  resolveComponent: components => components.CFIpTruncation,
});

export const CFKey = loadable(() => import('./index'), {
  resolveComponent: components => components.CFKey,
});

export const CFLeaderCrown = loadable(() => import('./index'), {
  resolveComponent: components => components.CFLeaderCrown,
});

export const CFLearningCenterBlock = loadable(() => import('./index'), {
  resolveComponent: components => components.CFLearningCenterBlock,
});

export const CFLink = loadable(() => import('./index'), {
  resolveComponent: components => components.CFLink,
});

export const CFLoading = loadable(() => import('./index'), {
  resolveComponent: components => components.CFLoading,
});

export const CFLocationPin = loadable(() => import('./index'), {
  resolveComponent: components => components.CFLocationPin,
});

export const CFLogoFacebook = loadable(() => import('./index'), {
  resolveComponent: components => components.CFLogoFacebook,
});

export const CFLogoGithub = loadable(() => import('./index'), {
  resolveComponent: components => components.CFLogoGithub,
});

export const CFMediaPause = loadable(() => import('./index'), {
  resolveComponent: components => components.CFMediaPause,
});

export const CFMediaPlay = loadable(() => import('./index'), {
  resolveComponent: components => components.CFMediaPlay,
});

export const CFMediaStop = loadable(() => import('./index'), {
  resolveComponent: components => components.CFMediaStop,
});

export const CFMore = loadable(() => import('./index'), {
  resolveComponent: components => components.CFMore,
});

export const CFMoreOutlined = loadable(() => import('./index'), {
  resolveComponent: components => components.CFMoreOutlined,
});

export const CFNetworkScale = loadable(() => import('./index'), {
  resolveComponent: components => components.CFNetworkScale,
});

export const CFNetworkVirtualBackbone = loadable(() => import('./index'), {
  resolveComponent: components => components.CFNetworkVirtualBackbone,
});

export const CFNoSecurityShield = loadable(() => import('./index'), {
  resolveComponent: components => components.CFNoSecurityShield,
});

export const CFNotification = loadable(() => import('./index'), {
  resolveComponent: components => components.CFNotification,
});

export const CFNumericAdd = loadable(() => import('./index'), {
  resolveComponent: components => components.CFNumericAdd,
});

export const CFNumericMinus = loadable(() => import('./index'), {
  resolveComponent: components => components.CFNumericMinus,
});

export const CFNumericOne = loadable(() => import('./index'), {
  resolveComponent: components => components.CFNumericOne,
});

export const CFNumericPlus = loadable(() => import('./index'), {
  resolveComponent: components => components.CFNumericPlus,
});

export const CFNumericSubtract = loadable(() => import('./index'), {
  resolveComponent: components => components.CFNumericSubtract,
});

export const CFNumericThree = loadable(() => import('./index'), {
  resolveComponent: components => components.CFNumericThree,
});

export const CFNumericTwo = loadable(() => import('./index'), {
  resolveComponent: components => components.CFNumericTwo,
});

export const CFOfficeBranch = loadable(() => import('./index'), {
  resolveComponent: components => components.CFOfficeBranch,
});

export const CFOfficeHeadquaters = loadable(() => import('./index'), {
  resolveComponent: components => components.CFOfficeHeadquaters,
});

export const CFOpenDoor = loadable(() => import('./index'), {
  resolveComponent: components => components.CFOpenDoor,
});

export const CFOptimizationGear = loadable(() => import('./index'), {
  resolveComponent: components => components.CFOptimizationGear,
});

export const CFOptimizationScale = loadable(() => import('./index'), {
  resolveComponent: components => components.CFOptimizationScale,
});

export const CFOptimizationWeb = loadable(() => import('./index'), {
  resolveComponent: components => components.CFOptimizationWeb,
});

export const CFOrbit = loadable(() => import('./index'), {
  resolveComponent: components => components.CFOrbit,
});

export const CFPerformance = loadable(() => import('./index'), {
  resolveComponent: components => components.CFPerformance,
});

export const CFPerformanceAccelerationBolt = loadable(() => import('./index'), {
  resolveComponent: components => components.CFPerformanceAccelerationBolt,
});

export const CFPerformanceAccelerationRocket = loadable(() => import('./index'), {
  resolveComponent: components => components.CFPerformanceAccelerationRocket,
});

export const CFPerformanceArrowUp = loadable(() => import('./index'), {
  resolveComponent: components => components.CFPerformanceArrowUp,
});

export const CFPerformanceCloudSpeed = loadable(() => import('./index'), {
  resolveComponent: components => components.CFPerformanceCloudSpeed,
});

export const CFPerformanceIntelligentRouting = loadable(() => import('./index'), {
  resolveComponent: components => components.CFPerformanceIntelligentRouting,
});

export const CFPerformanceRouting = loadable(() => import('./index'), {
  resolveComponent: components => components.CFPerformanceRouting,
});

export const CFPerformanceValidator = loadable(() => import('./index'), {
  resolveComponent: components => components.CFPerformanceValidator,
});

export const CFPerformanceWrench = loadable(() => import('./index'), {
  resolveComponent: components => components.CFPerformanceWrench,
});

export const CFPlatformApps = loadable(() => import('./index'), {
  resolveComponent: components => components.CFPlatformApps,
});

export const CFPower = loadable(() => import('./index'), {
  resolveComponent: components => components.CFPower,
});

export const CFPremiumSuccessOffering = loadable(() => import('./index'), {
  resolveComponent: components => components.CFPremiumSuccessOffering,
});

export const CFPrice = loadable(() => import('./index'), {
  resolveComponent: components => components.CFPrice,
});

export const CFPrinter = loadable(() => import('./index'), {
  resolveComponent: components => components.CFPrinter,
});

export const CFProcessFlow = loadable(() => import('./index'), {
  resolveComponent: components => components.CFProcessFlow,
});

export const CFProcessStack = loadable(() => import('./index'), {
  resolveComponent: components => components.CFProcessStack,
});

export const CFPullRequest = loadable(() => import('./index'), {
  resolveComponent: components => components.CFPullRequest,
});

export const CFPullRequestMerged = loadable(() => import('./index'), {
  resolveComponent: components => components.CFPullRequestMerged,
});

export const CFRefresh = loadable(() => import('./index'), {
  resolveComponent: components => components.CFRefresh,
});

export const CFRegionalServices = loadable(() => import('./index'), {
  resolveComponent: components => components.CFRegionalServices,
});

export const CFReliabilityDns = loadable(() => import('./index'), {
  resolveComponent: components => components.CFReliabilityDns,
});

export const CFReliabilityDnsResolver = loadable(() => import('./index'), {
  resolveComponent: components => components.CFReliabilityDnsResolver,
});

export const CFReliabilityLoadBalance = loadable(() => import('./index'), {
  resolveComponent: components => components.CFReliabilityLoadBalance,
});

export const CFReliabilityTimer = loadable(() => import('./index'), {
  resolveComponent: components => components.CFReliabilityTimer,
});

export const CFRevert = loadable(() => import('./index'), {
  resolveComponent: components => components.CFRevert,
});

export const CFRouter = loadable(() => import('./index'), {
  resolveComponent: components => components.CFRouter,
});

export const CFSalelite = loadable(() => import('./index'), {
  resolveComponent: components => components.CFSalelite,
});

export const CFSearch = loadable(() => import('./index'), {
  resolveComponent: components => components.CFSearch,
});

export const CFSecurityBots = loadable(() => import('./index'), {
  resolveComponent: components => components.CFSecurityBots,
});

export const CFSecurityCrawler = loadable(() => import('./index'), {
  resolveComponent: components => components.CFSecurityCrawler,
});

export const CFSecurityDDOSProtection = loadable(() => import('./index'), {
  resolveComponent: components => components.CFSecurityDDOSProtection,
});

export const CFSecurityFingerprint = loadable(() => import('./index'), {
  resolveComponent: components => components.CFSecurityFingerprint,
});

export const CFSecurityLock = loadable(() => import('./index'), {
  resolveComponent: components => components.CFSecurityLock,
});

export const CFSecurityProtection = loadable(() => import('./index'), {
  resolveComponent: components => components.CFSecurityProtection,
});

export const CFSecuritySafe = loadable(() => import('./index'), {
  resolveComponent: components => components.CFSecuritySafe,
});

export const CFSecurityScraping = loadable(() => import('./index'), {
  resolveComponent: components => components.CFSecurityScraping,
});

export const CFSecurityWaf = loadable(() => import('./index'), {
  resolveComponent: components => components.CFSecurityWaf,
});

export const CFSecurityZeroTrust = loadable(() => import('./index'), {
  resolveComponent: components => components.CFSecurityZeroTrust,
});

export const CFServerDatabase = loadable(() => import('./index'), {
  resolveComponent: components => components.CFServerDatabase,
});

export const CFServerDouble = loadable(() => import('./index'), {
  resolveComponent: components => components.CFServerDouble,
});

export const CFServerOrigin = loadable(() => import('./index'), {
  resolveComponent: components => components.CFServerOrigin,
});

export const CFServerRows = loadable(() => import('./index'), {
  resolveComponent: components => components.CFServerRows,
});

export const CFServerStacked = loadable(() => import('./index'), {
  resolveComponent: components => components.CFServerStacked,
});

export const CFShare = loadable(() => import('./index'), {
  resolveComponent: components => components.CFShare,
});

export const CFShopCart = loadable(() => import('./index'), {
  resolveComponent: components => components.CFShopCart,
});

export const CFShopCartNo = loadable(() => import('./index'), {
  resolveComponent: components => components.CFShopCartNo,
});

export const CFSpamEmail = loadable(() => import('./index'), {
  resolveComponent: components => components.CFSpamEmail,
});

export const CFStop = loadable(() => import('./index'), {
  resolveComponent: components => components.CFStop,
});

export const CFStopX = loadable(() => import('./index'), {
  resolveComponent: components => components.CFStopX,
});

export const CFSupportChat = loadable(() => import('./index'), {
  resolveComponent: components => components.CFSupportChat,
});

export const CFTarget = loadable(() => import('./index'), {
  resolveComponent: components => components.CFTarget,
});

export const CFTargetRetarget = loadable(() => import('./index'), {
  resolveComponent: components => components.CFTargetRetarget,
});

export const CFTime = loadable(() => import('./index'), {
  resolveComponent: components => components.CFTime,
});

export const CFTrafficAttack = loadable(() => import('./index'), {
  resolveComponent: components => components.CFTrafficAttack,
});

export const CFTrafficLegit = loadable(() => import('./index'), {
  resolveComponent: components => components.CFTrafficLegit,
});

export const CFUser = loadable(() => import('./index'), {
  resolveComponent: components => components.CFUser,
});

export const CFUserAddMember = loadable(() => import('./index'), {
  resolveComponent: components => components.CFUserAddMember,
});

export const CFUserMulti = loadable(() => import('./index'), {
  resolveComponent: components => components.CFUserMulti,
});

export const CFXExit = loadable(() => import('./CFXExit'), {
  resolveComponent: components => components.CFXExit,
});
